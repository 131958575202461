<template>
  <v-app>
    <div
      v-if="environment !== 'prod'"
      class="text-center pa-2"
      :class="{
        'red darken-4 white--text': environment === 'formation',
        'blue darken-2 white--text': environment === 'preprod'
      }"
    >
      Base de {{ environment }}
    </div>

    <div v-if="!isConnected">
      <div
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col class="flex-grow-0">
            <spring-spinner
              :animation-duration="3000"
              :size="60"
              color="#7fbc56"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <v-app-bar v-if="isConnected">
      <v-toolbar-title
        :class="{
          'red accent-2': environment === 'formation',
          'blue darken-2': environment === 'preprod'
        }"
      >
        <img
          class="logo"
          src="./assets/logo.png"
          alt="FCPE Adhésions"
          @click="goHome"
        >
      </v-toolbar-title>
      <span>{{ getMenuActiveGroup ? getMenuActiveGroup.title : '' }} </span> <span v-if="getMenuActiveSubgroup"> - {{ getMenuActiveSubgroup.title }}</span>
      <v-spacer />
      <v-btn
        class="mr-2"
        color="error"
        @click="disconnect"
      >
        <v-icon>lock</v-icon>Deconnexion
      </v-btn>
    </v-app-bar>

    <v-main v-if="isConnected">
      <v-row class="no-gutters h-100">
        <v-col class="col-auto">
          <Menu
            :class="{
              'red accent-2': environment === 'formation',
              'blue darken-2': environment === 'preprod'
            }"
          />
        </v-col>
        <v-col class="col col-custom">
          <div class="pa-5">
            <router-view />
          </div>
        </v-col>
      </v-row>
    </v-main>

    <v-snackbar
      :value="getError.display"
      :vertical="true"
    >
      <p v-if="getError.info">
        {{ getError.info }}
      </p>
      <p v-if="getError.message">
        {{ getError.message }}
      </p>
      <v-btn
        text
        color="error"
        @click="hideErrors"
      >
        Fermer
      </v-btn>
    </v-snackbar>

    <v-snackbar
      :value="getSuccess.display"
      color="success"
    >
      <p v-if="getSuccess.info">
        {{ getSuccess.info }}
      </p>
      <p v-if="getSuccess.message">
        {{ getSuccess.message }}
      </p>
      <v-btn
        text
        @click="hideSuccess"
      >
        Fermer
      </v-btn>
    </v-snackbar>
    <v-footer
      v-if="isConnected"
      class="pa-3"
    >
      <v-spacer />
      <div>&copy; {{ new Date().getFullYear() }}</div>
    </v-footer>
  </v-app>
</template>
<style lang="scss">
@import './styles/style';
</style>

<style lang="scss" scoped>
@import './styles/components/App';
@import './styles/components/Header';
@import './styles/components/Badge';
@import './styles/components/Footer';
</style>


<script>
import Menu from './components/partials/Menu';
import auth from './auth';
import VueRouter from 'vue-router';
import { mapGetters, mapActions } from 'vuex';
import { SpringSpinner } from 'epic-spinners';

export default {
  name: 'App',
  components: {
    Menu,
    SpringSpinner,
  },
  computed: {
    ...mapGetters('session', [
      'getUser',
      'isConnected',
      'getError',
      'getSuccess',
    ]),
    ...mapGetters('navigation', [
      'getMenuActiveGroup',
      'getMenuActiveSubgroup',
    ]),
    environment() {
      if (window.document.domain === 'app.fcpe-adhesion.fr') {
        return 'prod';
      }

      if (window.document.domain === 'app-formation.fcpe-adhesion.fr') {
        return 'formation';
      }

      if (window.document.domain === 'app-preprod.fcpe-adhesion.fr') {
        return 'preprod';
      }

      return 'developpement';

    },
  },
  methods: {
    ...mapActions(['resetState']),
    ...mapActions('session', [
      'setErrors',
      'hideErrors',
      'setSuccess',
      'hideSuccess',
    ]),
    goHome() {
      this.$router.push({ name: 'home' })
        .catch(e => {
          // if we got redirected to forbidden, an error Redirected from '/*' to '/' via a navigation guard
          // is raised, we dont want this error to be raised so we intercept it
          // but we still reject any other error as normal 
          if (!VueRouter.isNavigationFailure(e, VueRouter.NavigationFailureType.redirected)) {
            Promise.reject(e);
          }
        });
    },
    disconnect() {
      this.$router.push({ name: 'home' }).then(() => {  auth.logout(); });
    },
    errorsGlobal() {
      this.setErrors();
    },
  },
};
</script>
